import React, { useState, useEffect } from "react";
import "./card1.css";

export default function Card1(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 800) {
      document.getElementById("card1-remove").style.display = "none";
    } else {
      document.getElementById("card1-remove").style.display = "flex";
    }
  }, [windowWidth]);

  return (
    <div id={props.idname2} className="card1-container">
      <div className="card1-card">
        <div id={props.idname} className="card1-box">
          <div className="card1-content">
            {/* <h2>{props.no}</h2> */}
            <h3>{props.title}</h3>
            <div className="card1-div">
              <p>{props.info}</p>
              <div className="card1-buttons">
                <a
                  href={props.rulebook}
                  target="blank"
                >
                  Rule Book
                </a>
                <a target="blank" href={props.register}>Register</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
