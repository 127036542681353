import React, { useEffect } from 'react';
import "./herobottom.css";
import Timer from '../timer/timer';
import AOS from "aos";
import "aos/dist/aos.css";
import { GrMapLocation } from "react-icons/gr";

export default function Herobottom() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className='herobottom'>
        <div data-aos="fade-up" data-aos-delay="1500" data-aos-once="true" className='rnxginfo'>
            <p>RNXG</p>
            <p>Members of the RNXG, regardless of their fields,
            collaborate as a team to generate multidisciplinary
            technical expertise. Robotics for Next Generation,
            or RNXG, is the greatest platform for working on 
            various issue statements and developing the best 
            solutions for them.
            </p>
            <a target='blank' href='https://www.rnxg.co.in/'><button>Explore</button></a>
        </div>
        <div data-aos="fade-up" data-aos-delay="1600" data-aos-once="true" className='clock' >
            <p>Days left</p>
            <Timer date="February, 09, 2024"/>
        </div>
        <div data-aos="fade-up" data-aos-delay="1700" data-aos-once="true" className='gameinfo' > 
            <p><span>4</span> <span>Games</span></p>
            <p><span>09, 10 & 11</span> <span>February 2024</span></p>
            <p><span><a style={{color : 'white'}} target='blank' href='https://maps.app.goo.gl/jykyPhJCzaufuvMaA'><GrMapLocation /></a></span> <span>SGGSIE&T, Nanded</span></p>
        </div>
    </div>
  )
}