import React, { useEffect } from "react";
import "./Spons.css";
import CustomCarousel2 from "../coursel/coursel";
import AOS from "aos";
import "aos/dist/aos.css";

const Spons = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div className="main">
      <div id="organisers" className="Main">
        <img
          src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947662/raqwjpxiqctr5hxoqox0.png"
          alt="background2"
          id="background"
        ></img>
        <div className="Box">
          <div className="spon-info-div">
            <p
              className="spon-first-p"
              data-aos="fade-right"
              data-aos-once="true"
            >
              Our Sponsors
            </p>
            {/* <div className="spon-swag">
              <div className="swag-logo"></div>
              <p
                className="spon-sec-p"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="200"
              >
                <span className="span1">SWAG </span> <br></br>SWAG stands for
                SGGS Web App and Graphics development Club. SWAG is official
                software developer club of SGGSIE&T. We are a group of
                enthusiastic coders who come together to learn, share knowledge
                and work on exciting projects. Our club is open to everyone,
                regardless of your experience level or background.
                <br />
                <br />
                This year Welcome to the forefront of technological innovation
                !!
                <br />
                <br />
                <span className="span2">
                  HackFusion: Igniting the Tech Revolution
                </span>
                <br />
                <br />
                <span className="span4">
                  <span className="span3">
                    🚀An electrifying hackathon curated by the SWAG
                    DeveloperClub!🚀
                  </span>
                  <a
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-delay="300"
                    href="https://swagdev.vercel.app/Hackfusion"
                  >
                    <button>Visit Now</button>
                  </a>
                </span>
              </p>
            </div> */}
            {/* <p data-aos="fade-up" data-aos-once="true" data-aos-delay="400">PHOENIX</p> */}
          </div>
          <div className="sponinfo">
            {/* <p>Our Sponsors</p> */}
            <CustomCarousel2 responsive={responsive} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spons;
