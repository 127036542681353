import React, { useEffect } from "react";
import "./heroinfo.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Heroinfo() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id="heroinfo" className="heroinfo">
      <div className="hero-info-div">
        <p data-aos="fade-zoom-in" id="heading" data-aos-delay="50" data-aos-once="true">RMAGEDDON-2024</p>
        <p data-aos="fade-zoom-in" data-aos-delay="500" data-aos-once="true" id="tagline">Orbiting Innovation: Where Sci-Fi Meets Technical Minds</p>
        <p data-aos="fade-zoom-in" data-aos-delay="600" data-aos-once="true" id="information">
          Unleash the full potential of your robotic creations and showcase your
          skills to the elite in the field of robotics. Compete for recognition
          and prizes at Rmageddon and be a part of the most exciting robotics
          event of the year!
        </p>
        <a data-aos="fade-zoom-in" data-aos-delay="900" data-aos-once="true" href="#unlock">
          <button>Explore Now</button>
        </a>
      </div>
      <div data-aos="fade-zoom-in" data-aos-delay="1200" data-aos-once="true" className="logo-div">
        <img
          src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947668/irp1isvvd62icry2hban.png"
          alt="logo"
        />
        {/* <div className="shadow"></div> */}
      </div>
    </div>
  );
}
