import { React, useEffect } from 'react';
import './Unlock.css';
import AOS from "aos";
import "aos/dist/aos.css";

const Unlock = () => {

    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <div className='main'>
            <div id='unlock' className='unlock-parentdiv'>
                <img alt='background' src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947662/raqwjpxiqctr5hxoqox0.png" id='background' />
                <div className='unlock-maindiv'>
                    <div data-aos="fade-right" data-aos-once="true" className='unlock-text-div'>
                    <p className='unlock-text'>Unlock Your Potential through Rmageddon <br />After Participating</p>
                    </div>
                    <ul className='unlock-content'>
                        <li data-aos="fade-right" data-aos-delay="100" data-aos-once="true">Development of teamwork and learn "GAMESMANSHIP" and "CREATIVITY" - a crucial 21st century skills.</li>
                        <li data-aos="fade-right" data-aos-delay="300" data-aos-once="true">Exceptional hands-on learning experience with designing, building and complement your engineering curriculum.</li>
                        <li data-aos="fade-right" data-aos-delay="500" data-aos-once="true"> Exciting Prizes,Certificates after completion and a ton of excitement.</li>
                        <li data-aos="fade-right" data-aos-delay="700" data-aos-once="true">By participating in the competition, familiarize yourself with SCI-FI realm.</li>
                    </ul>
                    <div className="award">
                        <div data-aos="fade-up" data-aos-delay="700" data-aos-once="true" className="cash-prize">
                            <p className="unlock-heading">Cash Prizes</p>
                            <p className='award-p'>Cash prize up to 1.2 lakh .</p>

                        </div>

                        <div data-aos="fade-up" data-aos-delay="800" data-aos-once="true" className="certificate">
                            <p className="unlock-heading">Certificate of Participation</p>
                            <p className='award-p'>Every Participant will get the Certificate</p>
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </div>
        </div>
    );

};


export default Unlock;