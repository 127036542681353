import React, { useEffect } from 'react';
import "./footer.css";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Footer() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <footer id='footer'>
      <div className="footer-row">
        <div data-aos="fade-right" data-aos-once="true" className="footer-col">
          <a target='blank' href="https://www.rnxg.co.in/" className="footer-logo">RNXG</a>
          <p>
            RNXG that is Robotics for Next Generation provides the best platform to work on different problem statements and to create best solutions for them.
          </p>
        </div>
        <ul className='footer-icons'>
          <li data-aos="fade-up" data-aos-delay="100" data-aos-once="true"><a target='blank' href='https://www.instagram.com/team_rnxg/'><FaInstagram /></a></li>
          <li data-aos="fade-up" data-aos-delay="200" data-aos-once="true"><a target='blank' href='https://www.facebook.com/rnxgsggs'><FaFacebookF /></a></li>
          <li data-aos="fade-up" data-aos-delay="300" data-aos-once="true"><a target='blank' href='https://www.linkedin.com/company/rnxg/mycompany/'><FaLinkedinIn /></a></li>
          <li data-aos="fade-up" data-aos-delay="400" data-aos-once="true"><a target='blank' href='https://www.youtube.com/channel/UC9fFrYx9UEpxDjS9YO6t2FA'><FaYoutube /></a></li>
        </ul>
      </div>
      <hr />
      <div className='bottom-footer'>
        <p className="copyright">RMAGEDDON © 2024 - All Rights Reserved</p>
        <a target='blank' href='https://mail.google.com/mail/?view=cm&fs=1&to=info@rmageddon.tech'>info@rmageddon.tech</a>
      </div>
        <p className='bottom-most-footer'>Design and Develop by Team RnXg</p>
    </footer>
  )
}
