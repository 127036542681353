import React, { useState, useEffect } from "react";
import "./navbar.css";
import { HiMenuAlt1 } from "react-icons/hi";
import AOS from "aos";
import "aos/dist/aos.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Navbar() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleClick = () => {
    document.getElementById("navbar").style.display = "flex";
  };

  const handleClose = () => {
    if (windowWidth < 1200) {
      document.getElementById("navbar").style.display = "none";
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 1200) {
      document.getElementById("navbar").style.display = "flex";
    } else {
      document.getElementById("navbar").style.display = "none";
    }
  }, [windowWidth]);

  return (
    <>
      <a href="https://www.rnxg.co.in/">
        <img
          data-aos="fade-down"
          data-aos-delay="2000"
          data-aos-once="true"
          className="rnxg-logo-div"
          src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947660/bkiddydhu95f2a5czmsv.png"
          alt="logo"
        />
      </a>
      <div data-aos="fade-down" data-aos-once="true" className="mobile">
        <button onClick={handleClick}>
          <HiMenuAlt1 />
        </button>
      </div>
      <nav
        data-aos="fade-down"
        data-aos-delay="2000"
        data-aos-once="true"
        id="navbar"
      >
        <div className="nav-ul-div">
          <ul>
            <li>
              <Link to={{ pathname: "/" }}>
                <a onClick={handleClose} href="#heroinfo">
                  Home
                </a>
              </Link>
            </li>
            <li>
              <Link to={{ pathname: "/games" }}>
                <a onClick={handleClose} href="#games">
                  Events
                </a>
              </Link>
            </li>
            <li>
              <Link to={{ pathname: "/accommodation" }}>
                <a onClick={handleClose} href="#accommodation">
                  Accommodation
                </a>
              </Link>
            </li>
            <li>
              <a onClick={handleClose} href="#footer">
                Contact Us
              </a>
            </li>
            <li>
              <a href="#abc" onClick={handleClose}>
                <AiOutlineCloseCircle />
              </a>
            </li>
          </ul>
        </div>
        <div className="nav-button-div">
          {/* <Link to={{ pathname: "/games" }}>
            <a href="#games">
              <button onClick={handleClose}>Games</button>
            </a>
          </Link> */}
          <div className="nav-div-cut">
          <div className="circle" id="circle1"></div>
          <div className="circle" id="circle2"></div>
          </div>
        </div>
      </nav>
    </>
  );
}
