import React, { useState, useEffect } from "react";
import "./maincard.css";
import book from "./path blazzer.pdf";

export default function Maincard() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 800) {
      document.getElementById("main-game-conatiner-div").style.display = "none";
    } else {
      document.getElementById("main-game-conatiner-div").style.display = "flex";
    }
  }, [windowWidth]);

  return (
    <div id="main-game-conatiner-div" className="main__container">
      <div className="main_card">
        <img
          src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947667/cnsbo99wpncg2xf2fzku.jpg"
          className="card__image"
          alt="brown couch"
        />
        <div className="card__content">
          <h1 className="card__title">Path Blazer</h1>
          <h2 className="card__subtitle">
            Encounter the realm of 'Path Blazer' : A RoboRace competition! The
            goal of Path Blazer competition is to build a wired robot to be
            operated manually and should travel through exciting turns and
            conquer all the obstacles. The robot that will finish the arena in
            minimal time will win this game.
          </h2>
          <div className="card_buttons">
            <a href={book} target="blank"><button>Rule Book</button></a>
            <a target="blank" href="https://unstop.com/p/path-blazerrobo-race-rmageddon-2024-shri-guru-gobind-singhji-institute-of-engineering-and-technology-sggsiet-nanded-868057">
              <button>Register</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
