import React from 'react';
import './Glimpse.css';
import CustomCarousel2 from './coursel';

function Glimpse(){

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide : 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide : 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide : 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide : 1,
    },
  };

  return (
    <div className="blackdiv">
      <p className="blackdiv-heading1">Last year Glimpses...</p>
      <CustomCarousel2 responsive={responsive} />
    </div>
  );
}

export default Glimpse;
