import React from 'react';
import "./loader.css";

export default function Loader() {

  return (
    <div className='loader'>
      <video id='video' autoPlay muted typeof='mp4/video'>
        <source src="https://res.cloudinary.com/dqrwkgoi8/video/upload/v1706947667/axegvxfjgict1zlgle1k.mp4"></source>
      </video>
    </div>
  )
}
