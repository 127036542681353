import React, { useEffect } from "react";
import "./celebration.css";
// import Confetti from "react-confetti";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Celebration() {
  const Cross = () => {
    // document.getElementById('navbar12').style.top='0px';
    document.getElementById("news").style.display = "none";
    // document.getElementById('sprinkle').style.display='none';
    // document.getElementById("confetti").style.display = "none";
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     Cross();
  //   }, 3000);
  // }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div data-aos="fade-down" data-aos-delay="2200" className="news" id="news">
        <div className="news-in">
          <h4>
            <center>Rmageddon-2024's registrations are started. Rules Books are Updated. </center>
          </h4>
          <div className="news-cancel">
            <a href="games"><button id='Know'>Know More</button></a>
            <button id='close' onClick={Cross}>
     <svg viewBox="0 0 24 24" width="24" height="24" class=""><path fill="#111111" d="m19.8 5.8-1.6-1.6-6.2 6.2-6.2-6.2-1.6 1.6 6.2 6.2-6.2 6.2 1.6 1.6 6.2-6.2 6.2 6.2 1.6-1.6-6.2-6.2 6.2-6.2z"></path></svg>
     </button>
          </div>
        </div>
      </div>
      {/* <Confetti id="confetti" height={1300} /> */}
    </>
  );
}
