import React from "react";
import "./accommodation.css";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";

export default function Accommodation() {
  return (
    <div className="accommodation-main-div">
      <Navbar />
      <div className="accom-div">
        <p>Accommodation registrations are started</p>
        <p><a href="https://forms.gle/XRJRhFggAFG9dvG38" target="blank">Register Here</a></p>
        <p>There are no accommodation charges. <br /> Limited slots are available.</p>
      </div>
      <Footer />
    </div>
  );
}
