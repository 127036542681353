"use client";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./coursel.css";

const CustomCarousel2 = (props) => {
  const responsive = props.responsive;

  return (
    <>
      <Carousel
        responsive={responsive}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={2000}
        infinite={true}
        swipeable={true}
        partialVisible={false}
        rewind={true}
        rewindWithAnimation={true}
        renderButtonGroupOutside={true}
        className="coursel"
        draggable={true}
        showDots={true}
      >
        <div className="Chota_card">
          <div className="Child_Box">
            <a target='blank' href="https://www.solidworks.com/">
              <img
                alt="animage"
                src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947670/zvdxa2gmlflg1ihdbuoh.png"
              />
            </a>
          </div>
          <h6>SOLIDWORKS</h6>
        </div>
        <div className="Chota_card">
          <div className="Child_Box">
            <a target='blank' href="https://roboticsindia.live/">
              <img
                alt="animage"
                src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947671/zfrtec09zfvl2pu22hp9.png"
              />
            </a>
          </div>
          <h6>THE ROBOTICS INDIA</h6>
        </div>
        <div className="Chota_card">
          <div className="Child_Box">
            <a target='blank' href="https://www.pcbpower.com/">
              <img
                alt="animage"
                src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947668/iztlyq6jjavpcjti1dbv.png"
              />
            </a>
          </div>
          <h6>PCB POWER</h6>
        </div>
        <div className="Chota_card">
          <div className="Child_Box">
            <a target='blank' href="https://www.skillsprk.tech/">
              <img
                alt="animage"
                src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947670/vjkurbxjjz3h7qax8atc.png"
              />
            </a>
          </div>
          <h6>SKILL SPARK</h6>
        </div>
      </Carousel>
    </>
  );
};

export default CustomCarousel2;
