"use client";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./coursel.css";

const CustomCarousel2 = (props) => {

  const responsive = props.responsive;

  return (
    <>
      <Carousel
        responsive={responsive}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={2000}
        infinite={true}
        swipeable={true}
        partialVisible={false}
        rewind={true}
        rewindWithAnimation={true}
        renderButtonGroupOutside={true}
        className="coursel-g"
        draggable={true}
      >
        <div><img alt="an" src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947665/nwbj9m2o2wm5fjcco07c.jpg" /></div>
        <div><img alt="an" src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947665/cdb10qyz03mmlhjajwvp.jpg" /></div>
        <div><img alt="an" src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947666/k4tyjfaqszszevx19qa8.jpg" /></div>
        <div><img alt="an" src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947666/i3b9vu7r9h48wqtcqgds.jpg" /></div>
        <div><img alt="an" src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947666/gfqknabcpjydyawtrtpv.jpg" /></div>
        <div><img alt="an" src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947669/zf40guschzxgggrqk3kz.png" /></div>
        <div><img alt="an" src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947670/wjvqqt8vw5vtagtz4oru.jpg" /></div>
        <div><img alt="an" src="https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947670/xwupx2dcbr50qvzhfzzh.png" /></div>
        {/* <div><img alt="an" src="https://res.cloudinary.com/doiocpcni/image/upload/v1704390633/Rmageddon2K24/Screenshot_83_j881dj.png" /></div> */}
      </Carousel>
    </>
  );
};

export default CustomCarousel2;
